/* App.css */

/* Global styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Avenir', 'Helvetica Neue', Arial, sans-serif;
  height: 100%;
  background-color: transparent;
  overflow: hidden; /* Prevent body from scrolling */
  -webkit-overflow-scrolling: touch;
  position: relative;
  /* Prevent pull-to-refresh and maintain scroll position */
  overscroll-behavior-y: none;
  overflow-y: auto;
  /* Optional: prevent bounce in iOS */
  position: fixed;
  width: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  background-color: #2b2c2e;
  padding: 20px;
  text-align: center;
  color: #333;
  position: relative;
  z-index: 1;
}

.footer-bottom {
  margin-top: auto;
  padding: 10px;
  background-color: #2b2c2e;
  text-align: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  background-color: transparent;
}

.app.privacy-policy-page .background-image {
  display: none;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  color: #fff; /* Text color */
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.header-transparent {
  background-color: transparent;
}

.header-black {
  background: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(8px);
}

/* Title styles */
.title {
  font-family: 'Playfair Display', serif;
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: 2px;
  color: white;
  margin: 0;
  margin-right: auto; /* Push the nav to the right */
}

.nav-container {
  position: relative;
}

.nav {
  display: flex;
  align-items: center;
  z-index: 999; /* Just below header */
}

.nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.nav-list > li {
  display: flex;
  align-items: center;
}

.nav-link {
  font-family: 'Playfair Display';
  text-decoration: none;
  color: #fff;
  margin: 0 15px;
  font-size: 1.25rem;
  font-weight: 400;  /* Slightly lighter weight for elegance */
  letter-spacing: 0.05em;  /* Subtle letter spacing */
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  padding: 0 3px;
  border-radius: 50px;
  transition: all 0.3s ease;
}

.nav-link:hover {
  color: #dcc282;  /* Golden hover color */
  letter-spacing: 0.08em;  /* Slightly increase spacing on hover */
}

.auth-button {
  text-decoration: none;
  color: #fff;
  margin: 0 15px;
  font-size: 1.25rem;
  font-weight: bold;
  font-family: Merriweather, serif;
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
  padding: 0 20px;
  border-radius: 50px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  justify-content: center;
  min-width: 120px;
  background: linear-gradient(to right, rgb(253, 164, 175), rgb(253, 230, 138)); /* rose-300 to amber-200 */
}

.auth-button:hover {
  opacity: 0.9;
}

/* Remove any default button styles that might interfere */
.auth-button:focus {
  outline: none;
}

.profile-link {
  display: flex;
  align-items: center;
}

.profile-icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.title-w {
  color: #dcc282;
}

.menu-toggle {
  display: none;
}

.main-content {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow-y: auto; /* Enable scrolling */
  -webkit-overflow-scrolling: touch;
}

.scrollable-content {
  flex: 1;
  min-height: 100vh; /* Ensure content is tall enough to scroll */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .title {
    font-size: 2rem;
    margin: 0;
    margin-right: auto;
    font-weight: 400;  /* Reduced from 700 to 400 */
    letter-spacing: 2px;
    color: white;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    font-family: Merriweather, serif;
  }

  .menu-toggle {
    display: contents;
    background: none;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    z-index: 1001;
    position: relative;
  }

  .hamburger-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 30px;
    height: 21px;
  }

  .hamburger-icon span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease;
  }

  .menu-toggle.open .hamburger-icon span:nth-child(1) {
    transform: translateY(9px) rotate(45deg);
  }

  .menu-toggle.open .hamburger-icon span:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle.open .hamburger-icon span:nth-child(3) {
    transform: translateY(-9px) rotate(-45deg);
  }

  .nav {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 999;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav.open {
    display: flex;
  }

  .nav-list {
    flex-direction: column;
    align-items: center;
  }

  .nav-link {
    margin: 20px 0;
    font-size: 2rem;
  }

  .auth-button {
    margin-top: 20px;
    width: auto;
    padding: 0 20px;
  }

  .close-menu {
    background: none;
    color: white;
    font-size: xx-large;
    text-align: -webkit-right;
  }

  .main-content {
    padding-bottom: env(safe-area-inset-bottom); /* For iOS safe areas */
    min-height: -webkit-fill-available; /* Fix iOS height issues */
  }
}

/* Ensure text alignment remains consistent */
.nav-link, .auth-button {
  line-height: 40px;
}

.empty-space {
  height: 140px; /* Adjust height as needed */
}

.nav.mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 999;
}

.nav-list {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-list li a {
  text-decoration: none;
  font-size: 1.1rem; /* default size for desktop */
  font-family: 'Playfair Display';

}

.auth-button.mobile {
  width: 35%;
  padding: 10px;
  margin-top: 10px;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 1.5rem;
  background: linear-gradient(to right, #FF7F8E, #FFD6B6);
 
}

.profile-link:hover {
  background-color: #dcc282;
  color: #333333;
  border-radius: 20px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .nav-list li a {
    font-size: 2rem;
  }
}

.title-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.title-link:hover {
  opacity: 0.9;
}

.mobile-credits-display {
  position: absolute;
  right: 60px;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(to right, #FF7F8E, #FFD6B6);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 2px 8px rgba(255, 127, 142, 0.2);
  transition: all 0.3s ease;
}

.mobile-credits-display:hover {
  box-shadow: 0 4px 12px rgba(255, 127, 142, 0.3);
  border-color: rgba(255, 255, 255, 0.3);
}

.mobile-credits-display:active {
  transform: translateY(-48%);
}

.mobile-credits-display .coin-icon {
  animation: shine 2s infinite;
}

.mobile-credits-display .coin-icon circle {
  stroke: white;
}

.mobile-credits-display .coin-icon text {
  fill: white;
}

@keyframes shine {
  0% { filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.5)); }
  50% { filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.7)); }
  100% { filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.5)); }
}

@media (max-width: 768px) {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 25px;
  }

  .title-link {
    padding: 0.5rem 0;
  }

  .title-link img {
    width: 40px;
    height: 40px;
  }
}

/* iOS Safari specific fixes */
@supports (-webkit-touch-callout: none) {
  /* Fix for iOS viewport height issues */
  .main-content {
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    padding-bottom: env(safe-area-inset-bottom);
  }

  /* Fix for iOS safe areas */
  .header {
    padding-top: env(safe-area-inset-top);
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }

  /* Ensure content doesn't get hidden under notch/home indicator */
  .app-container {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }

  /* Fix for iOS scrolling */
  body {
    -webkit-overflow-scrolling: touch;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  /* Fix for iOS momentum scrolling */
  .scrollable-content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    position: relative;
  }
}

/* Additional mobile fixes */
@media (max-width: 768px) {
  .header {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) 12px env(safe-area-inset-left);
  }

  .nav.open {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }

  /* Ensure buttons are easily tappable on iOS */
  .nav-link, 
  .auth-button {
    min-height: 44px; /* iOS minimum tappable area */
    padding: 12px 20px;
  }
}

/* Fix for notched iPhones */
@media screen and (orientation: landscape) {
  @supports (padding: max(0px)) {
    .header {
      padding-left: max(20px, env(safe-area-inset-left));
      padding-right: max(20px, env(safe-area-inset-right));
    }
  }
}

#root {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/* For decorative elements */
.accent-text {
  font-family: 'Great Vibes', cursive;
  font-size: 1.5rem;
}